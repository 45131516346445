@import '../../styles/global.scss';

.flag-admin-wrapper {
  border: 1px solid black;
  margin-bottom: 1em;
  padding: 2em;
  max-height: 25vh;
  overflow-y: scroll;
  font-family: $lato;

  h1 {
    margin: 0;
  }

  .flag-list {
    max-height: 15vh;
    overflow-y: scroll;

    li {
      button {
        margin-left: 1em;
      }
    }
  }
}