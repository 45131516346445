// TYPOGRAPHY
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap');

@font-face {
  font-family: 'Gotham Condensed Bold';
  src: local('Gotham Condensed Bold'), url(../assets/fonts/GothamCondensed-Bold.woff) format('woff');
}

$gotham: 'Gotham Condensed Bold', sans-serif;
$lato: Lato, sans-serif;
$roboto: Roboto, sans-serif;

// RESPONSIVE MIXINS

@mixin mobile-portrait() {
  @media screen and (max-width: 1066px) and (orientation: portrait) {
    @content;
  }
}

@mixin mobile-landscape() {
  @media screen and (max-width: 1066px) and (orientation: landscape) {
    @content;
  }
}

// COLOURS

$orange: #cb6805;


// WRAPPER 

body {
  background-color: #ffdd02; 
  margin: 0 auto;

  @include mobile-landscape() {
    margin: 0 auto;
  }
}

header.page-header {
  img {
    height: 6em; 
    width: 6em; 
    object-fit: contain;

    @include mobile-landscape() {
      height: 3em;
      width: 3em;
    }
  }

  h1 {
    font-size: 4em; 
    font-family: $gotham;

    @include mobile-landscape() {
      font-size: 2em;
    }
  }
}
