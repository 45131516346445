@import '../../../styles/global.scss';

.create-offer-window {
  background-image: url('../../../assets/img/create-order.png');
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  height: 75.5%;
  width: 87%;
  top: 16%;
  left: 3%;
  transform: rotateX(2deg);

  @include mobile-landscape {
    height: 81.5%;
    width: 80%;
    top: 12%;
    transform: none; 
  }

  .create-amount-text {
    position: absolute;
    top: 39.6%;
    left: 37%;
  }

  .create-customer-text {
    position: absolute;
    top: 50%;
    left: 42%;
  }

  .create-order-confirm {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    border: none;
    background-color: #381284;
    padding: .6em 1.2em;
    color: white;
    font-size: 1em;
    border-radius: .6em;
    cursor: pointer;
  }
}