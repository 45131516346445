@import '../../styles/global.scss';

@keyframes result-appear {
  0% {
    transform: translate(calc(1000px - 50% + 4em), -50%);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}

.container .container-inner > .result-wrapper {
  left: 50%;
  top: 50%;
  box-sizing: border-box;
  padding: 2em;

  @include mobile-landscape {
    padding: 0;
  }
}

.result-wrapper {
  z-index: 10;
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  left: 50%;
  top: 50%;
  transform: translate(calc(1000px - 50% + 4em), -50%);
  opacity: 0;
  animation-name: result-appear;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-duration: .8s;
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);

  .content {
    padding: 2em 1em 2em 6em;
    background: white;
    flex: 1;
    font-size: 0.8em;

    @include mobile-landscape {
      padding: 0em 1em 2em 2em;
    }
    
    .result-button-wrapper,
    .result-text {
      padding-right: 2.5em;
    }

    .result-text {        

      h2 {
        font-family: $gotham;
        text-transform: uppercase;
        font-size: 3em;
        margin-right: 2em;
        font-weight: bolder;
        margin-top: 0px;

        @include mobile-landscape {
          font-size: 2em;
        }
      }

      p {
        margin-bottom: 1em;
        margin-right: 2em;
        font-size: 1.3em;
        line-height: 1.4em;

        @include mobile-landscape {
          font-size: 1em;
        }
      }

      ul {
        li {
          margin-bottom: .4em;
          margin-right: 2em;
          font-size: 1.3em;
          line-height: 1.4em;

          @include mobile-landscape {
            font-size: 1em;
          }
        }
      }
    }

    button {
      border: none;
      border-radius: 1em;
      background: $orange;
      color: white;
      padding: 1em 1.2em;
      font-size: 1em;
      box-shadow: 0 0 .2em .1em rgba(0, 0, 0, 0.3);
      cursor: pointer;
      margin: 1em auto;
      display: block;
  
      &:hover {
        background: lighten($orange, 10);
      }
    }
  }
}