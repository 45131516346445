@import '../../../styles/global.scss';

.intro-wrapper {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;

  .intro-content {
    padding: 2em 5em;
    font-size: 1.3em;
    line-height: 1.6em;

    @include mobile-landscape {
      padding: 1em 3em;
      font-size: 0.9em;
      line-height: 1.4em;
    }

    ul {
      font-weight: bold;
    }

    button {
      display: block;
      margin: 2em auto;
      background: black;
      color: white;
      border: none;
      padding: 1em 1em 1em 2em;
      font-size: 1.3em;
      cursor: pointer;
      display: flex;
      gap: .5em;
      align-items: center;

      @include mobile-landscape {
        margin-top: 1em;
        padding: 1em 3em;
      }

      &:hover {
        background: $orange;

        img {
          transform: translateX(50%);
        }
      }

      img {
        height: 1em;
        width: 1em;
        display: inline-block;
        object-fit: contain;
        transform: translateX(0);
        transition: .3s transform;
      }
    }
  }
}