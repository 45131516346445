@import '../../styles/global.scss';

.overlay-wrapper {
  height: 100%;
  width: 100%;
  z-index: 40;
  position: absolute;
  inset: 0;

  button {
    border: none;
    border-radius: .5em;
    background: $orange;
    color: white;
    padding: 1em 1.2em;
    font-size: 1em;
    box-shadow: 0 0 .28em .048em rgba(0, 0, 0, 0.3);
    cursor: pointer;

    &:hover {
      background: lighten($orange, 10);
    }
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;

    @include mobile-landscape {
      object-fit: cover;
    }
  }

  .overlayTimer {
    position: absolute;
    bottom: 5%;
    right: 5%;
    display: flex;
    gap: 2em;

    .value {
      color: white;
    }
  }
}