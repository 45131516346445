@import '../../styles/global.scss';

.message-pane {
  position: relative;
  height: 100%;
  width: 100%;

  .contact-inner {
    font-family: 'Roboto', sans-serif;
    position: absolute;
    width: 25.5%;
    height: 83.5%;
    // left: 39.5%;
    // top: 1%;
    left: 39.3%;
    top: 7.9%;
    transform: rotate(27.75deg);
    border-radius: 1.76em;
    background-color: white;

    @include mobile-landscape {
      width: 26%;
      height: 107%;
      left: 39%;
      top: 7%;
      border-radius: 1.1em;
    }

    h2.message-title {
      padding: .8em 1em;
      margin: 0;
      background-color: #0184ff;
      border-radius: 1.2em 1.2em 0 0;
      color: white;
      display: flex;
      align-items: center;

      @include mobile-landscape {
        border-radius: 0.6em 0.6em 0 0;
        font-size: 1em;
      }

      &:before {
          content: '';
          display: block;
          background-image: url('../../assets/img/icon-arrow-back.svg');
          background-size: contain;
          height: 1em;
          width: 1em;
          margin-right: .4em;
          cursor: pointer;
        }
      // }
    }
  }

  .contact-list {
    list-style-type: none;
    padding: 0;
    grid-column: 1/2;
    max-height: 20vh;
    overflow-y: scroll;
    margin: 0;

    .contact-item {
      border-bottom: .1em solid lightgray;
      padding: .5em;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.active, &:hover {
        background-color: lightgrey;
      }

      span.contact-person {
        display: flex;
        align-items: center;
      }

      img {
        height: 2em;
        width: 2em;
        margin-right: 1em;
        object-fit: contain;
      }

      .messageCount {
        background: palevioletred;
        color: white;
        height: 1.5em;
        width: 1.5em;
        display: inline-flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        font-size: .8em;
        border-radius: 50%;
      }
    }
  }

  .message-list {
    list-style-type: none;
    padding: 0;
    grid-column: 2/5;
    padding: 0 0 6em 1em;
    height: 85%;
    overflow-y: scroll;

    @include mobile-landscape {
      height: 82%;
    }

    .message-item {
      color: white;
      padding: 1em;
      margin-right: 1em;
      margin-bottom: 1em;
      border-radius: .8em;

      .message-body {
        word-wrap: break-word;
      }

      &.incoming {
        background-color: SlateGray;
      }

      &.outgoing {
        background-color: darkgreen;
        text-align: right;
      }

      @include mobile-landscape {
        font-size: .9em;
      }
    }

  }
}