@import '../../styles/global.scss';

@keyframes email-item-appear {
  0% {
    opacity: 0;
    max-height: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    max-height: 100vh;
    transform: translateY(0);
  }
}
  
.email-client {
  position: absolute;
  top: 14%;
  left: 2%;
  width: 96%;
  height: 76%;
  background-color: white;
  border-radius: 1em;
  box-shadow: 0 0 .48em .048em transparentize(#000000, .8);
  overflow: hidden;
  font-family: $lato;
  transform: rotateX(2deg);

  @include mobile-landscape {
    top: 6%;
    height: 92%;
    transform: none;
  }
  
  .email-topbar {
    background-color: #9dcbd4;
    background-image: url('../../assets/img/email-topbar.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 16%;
    border-radius: 1em 1em 0 0;
  }

  .email-sidebar {
    background-image: url('../../assets/img/email-sidebar.svg');
    height: 85%;
    width: 23%;
    background-repeat: no-repeat;
    background-position: center;
    border-right: .1em solid lightgray;
  }

  h1.email-list-title {
    position: absolute;
    top: 19%;
    left: 28%;
    font-size: 140%;
  }

  ul.email-list {
    list-style-type: none;
    padding: 0;
    overflow-y: scroll;
    position: absolute;
    top: 32%;
    left: 28%;
    height: 62%;
    width: 72%;

    .email-item {
      border-bottom: .1em solid lightgray;
      border-left: .5em solid white;
      padding: .3em;
      cursor: pointer;
      font-size: .8em;
      display: grid;
      grid-template-columns: repeat(11, minmax(auto, 1fr));

      &:before {
        content: '';
        height: 100%;
        width: 100%;
        grid-column: 1/2;
        background-image: url('../../assets/img/icon-email-buttons.svg');
        background-size: 80%;
        background-position: center;
        background-repeat: no-repeat;
      }
      

      p {
        font-size: 1.1em;
        margin: .3em auto;
        text-align: left;
        width: 100%;

        &:first-child {
          // font-weight: bolder;
          grid-column: 2/7;
        }

        &:nth-child(2) {
          grid-column: 7/12;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;  
        }
      }

      &.unread {
        border-left: .5em solid #187093;
        max-height: 0;
        opacity: 0;
        transform: translateY(-100%);
        animation: email-item-appear .5s 1;
        animation-fill-mode: forwards;
        background-color: lightblue;

        p:first-child {
          font-weight: bolder;
        }
      }

      &.active, &:hover {
        background-color: lightgray;
      }
    }
  }
}



@keyframes email-open {
  0% {
    transform: rotateX(2deg) scale(0);
  }
  100% {
    transform: rotateX(2deg) scale(1);
  }
}

.email-reading-pane {
  position: absolute;
  top: 19%;
  left: 3%;
  width: 94%;
  height: 70%;
  box-shadow: 0 0 .8em 0 rgba(0,0,0,0.2);
  border-radius: .6em;
  z-index: 10;
  background: white;
  transform: rotateX(2deg) scale(0);
  animation-name: email-open;
  animation-duration: .5s;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);

  @include mobile-landscape {
    top: 9%;
    height: 86%;
    overflow: hidden;
    left: 5%;
    width: 95%;

    &.active-options {
      left: 24%;
      width: 74%;
    }
  }

  & > * {
    padding: .6em 1.2em;
  }

  .email-subject {
    background-color: #187093;
    font-weight: bolder;
    color: white;
    font-size: 1.2em;
    display: flex;
    align-items: center;
    border-radius: 0.55em 0.55em 0 0;
    
    p {
      flex: 1;
      margin: 0;
    }

    button {
      border: 0;
      background-color: #187093;
      background-image: url('../../assets/img/icon-close.svg');
      background-size: contain;
      background-repeat: no-repeat;
      height: 1.7em;
      width: 1.7em;
      background-size: 1em;
      background-position: center;
      cursor: pointer;
    }
  }

  .email-from {
    font-size: 1.1em;
    border-bottom: .08em solid lightgray;
  }

  .email-body {
    font-size: 1.1em;
    overflow-y: scroll;
    height: 85%;
    padding: .6em 1.2em;
    
    & > * {
      margin-bottom: 1.5em;
      line-height: 1.4em;
    }
  }
}