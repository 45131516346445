@import '../../../styles/global.scss';

.summary-wrapper {
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;

  .summary-content {
    text-align: center;

    h2 {
      font-family: $gotham;
      font-size: 3em;
      text-transform: uppercase;
      margin: 1em 0;

      @include mobile-landscape {
        font-size: 2em;
      }
      
    }

    a.button,
    button {
      border: none;
      border-radius: 1em;
      background: $orange;
      color: white;
      padding: 1em 1.2em;
      font-size: 1em;
      box-shadow: 0 0 .24em .048em rgba(0, 0, 0, 0.3);
      text-decoration: none;
      font-weight: bolder;;
      cursor: pointer;
  
      &:hover {
        background: lighten($orange, 10);
      }
    }

    p {
      margin: 3em 0 1em;
      font-size: 1.4em;
      color: $orange;

      @include mobile-landscape {
        margin: 1em 0 1em;
      }
    }

    ul.social-list {
      display: flex;
      list-style-type: none;
      justify-content: center;
      gap: 1em;
      margin: 0;
      padding: 0;

      li {
        img {
          width: 4em;
          height: auto;

          @include mobile-landscape {
            width: 3em;
          }
        }

        button {
          box-shadow: none;
        }
      }
    }
  }
}