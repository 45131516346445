@import '../../../styles/global.scss';

.bank-transfer-window {
  background-image: url('../../../assets/img/bank-transfer.png');
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  height: 75.5%;
  width: 87%;
  top: 16%;
  left: 3%;
  transform: rotateX(2deg);

  @include mobile-landscape {
    height: 83.5%;
    top: 11%;
    transform: none; 
  }
}

.bank-transfer-confirm {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  border: none;
  background-color: $orange;
  padding: .6em 1.2em;
  color: white;
  font-size: 1em;
  border-radius: .6em;
  cursor: pointer;
}

p {
  margin: 0;
}

.from-text {
  position: absolute;
  top: 36.8%;
  left: 17%;
}

.to-text {
  position: absolute;
  top: 50.2%;
  left: 14%;
}

.payment-date {
  position: absolute;
  top: 61.5%;
  left: 24%;
}

.sort-code {
  position: absolute;
  top: 35.8%;
  left: 65%;
}

.account-number {
  position: absolute;
  top: 50.2%;
  left: 71.5%;
}

.amount {
  position: absolute;
  top: 61.5%;
  left: 63.5%;
}