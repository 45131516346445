@import '../../../styles/global.scss';

.shutdown-desk-wrapper {
  height: 100%;
  width: 100%;

  img {
    height: 100%;
    width: 100%; 
    object-fit: cover;
    object-position: center;
  }

}

.shutdown-wrapper {

  background: rgba(255,0,0,0.5);
   position: absolute;
   top: 50%;
   left: 50%;
   width: 27.3%;
   height: 23.4%;
   transform: rotateX(14.2deg) translate(-48.5%, -41%);
   background-color: #74EBD5;
   background-image: linear-gradient(0deg, #74EBD5 0%, #9FACE6 100%);
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;

   @include mobile-landscape() {
    height: 26.4%;
   }

   p {
    color: white;
    font-weight: bolder;
    font-size: 1.2em;
    margin: 0em;
   }
   
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 5%;
  height: 9%;
}
.loader {
  margin: 6em auto;
  font-size: .5em;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;

  @include mobile-landscape {
    margin: 3em auto;
  }
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}