@import '../../../styles/global.scss';

@keyframes notification-appear {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
  
}

.notification-wrapper {
  position: absolute;
  display: flex;
  max-width: 28%;
  padding: 1% 1% 1% 2%;
  align-items: center;
  border-radius: .4em;
  box-shadow: 0 0 .48em .048em transparentize(black, .7);
  cursor: pointer;
  animation: notification-appear .3s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;

  @include mobile-landscape {
    max-width: 55%;
  }

  &:hover > p:after {
    transform: translateX(50%);
  }

  >img {
    width: 20%;
    height: 100%;
    object-fit: contain;
    margin-right: 6%;
  }

  > p {
    color: white;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    font-size: 1em;

    @include mobile-landscape {
      font-size: 1.3em;
    }

    &:after {
      content: '';
      display: block;
      margin-left: 4%;
      background-image: url('../../../assets/img/icon-chevron-right.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      height: 1em;
      width: 1em;
      transform: translateX(0);
      transition: transform .3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    }
  }
}