@import '../../../styles/global.scss';

.focus-switcher-wrapper {
  border: 1px solid black;
  margin-bottom: 1em;
  padding: 2em;
  max-height: 25vh;
  overflow-y: scroll;
  font-family: $lato;

  h1 {
    margin: 0;
  }


  ul {
    list-style-type: none;
    display: flex;
    gap: 10px;
    padding: 0;

    li {
      button {
        border: none;
        background-color: rgb(98%, 70.7%, 0%);
        color: white;
        text-transform: uppercase;
        font-size: 1.2em;
        padding: .5em 2em;
        border-radius: 10px;
        box-shadow: 0 0 10px 1px rgba(0,0,0,.2);
        cursor: pointer;

        &:hover {
          background-color: lighten(rgb(98%, 70.7%, 0%), 10);
        }
      }

      &.active {
        button {
          background-color: rgb(7%, 76.5%, 100%);

          &:hover {
            background-color: lighten(rgb(7%, 76.5%, 100%), 10);
          }
        }
      }
    }
  }
}