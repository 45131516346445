@import '../../styles/global.scss';

@keyframes imagesAppear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  
}

.teabreak-wrapper {
  padding: 2em;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../../assets/img/bg-teabreak.jpg');
  position: relative;

  .teabreak-controls {
    .subtitle-wrapper {
      position: absolute;
      top: 30%;
      right: 2%;
      width: 40%;

      @include mobile_landscape {
        top: 12%;
        width: 70%;
      }
    }

    .teabreak-images-wrapper {
      img {
        position: absolute;
        width: 31%;
        opacity: 0;
        animation-name: imagesAppear;
        animation-duration: .5s;
        animation-fill-mode: forwards;
        animation-direction: normal;
        animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);

        &:first-child {
          transform: rotate(7deg);
          top: 5%;
          animation-delay: 0s;
        }

        &:nth-child(2) {
          transform: rotate(-12deg);
          top: 35%;
          animation-delay: .3s;
        }

        &:nth-child(3) {
          transform: rotate(12deg);
          top: 65%;
          animation-delay: .7s;
        }
      }
    }
  }
}