.monitor-inner {
  position: absolute;
  top: 12.5%;
  left: .8%;
  width: 98.5%;
  height: 79%;
  transform: rotateX(2deg);
  overflow: hidden;
}

.copying-window {
  position: absolute;
  top: 20%;
  left: 5%;
  width: 40%;
  height: 30%;
  padding: 2%;
  background: rgba(0,0,0,.5);
  border-radius: .4em;
  box-shadow: 0 0 1em 0 rgba(0,0,0,0.5);
  color: white;

  h2 {
    margin: .5em 0;
  }

  img {
    width: 90%;
    height: auto;
    position: absolute;
    top: 25%;
  }
}

.browser-wrapper.banking-takeover {
  background-image: url('../../../assets/img/bg-browser.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  height: 66%;
  width: 70%;
  position: absolute;
  top: 30%;
  left: 33%;
  transform: rotateX(2deg);
  box-shadow: 0 0 1em 0 rgba(0,0,0,0.5);

  .browser-url {
    position: absolute;
    top: 8%;
    left: 15%;
    font-size: .8em;
  }

  .browser-inner {
    position: absolute;
    top: 14%;
    width: 100%;
    height: calc(86% - 2em);
    padding: 1em;
    background: #2596be;
    justify-content: flex-start;
    align-items: flex-start;
    color: white;
    display: flex;
    flex-direction: column;

    .banking-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1em;
      padding: 0 1em;

      img {
        height: auto;
        width: 5%;
        object-fit: contain;
      }

      p {
        font-size: 2em;
        color: white;
      }
    }

    .banking-body {
      & > div {
        display: grid;
        grid-template-columns: repeat(2, minmax(auto, 1fr));
        gap: 2em;
        margin-bottom: 1em;

        
      }
    }
  }
}