@import '../../../styles/global.scss';

.options-wrapper {
  background-color: transparentize(white, .1);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2.3em;
  z-index: 10;

  @include mobile-landscape {
    top: 0;
    right: auto;
    padding: 1em;
    width: 20%;
    overflow-y: scroll;
    padding-top: 4em;
  }

  .options-list {
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 1em;
    margin: 0;
    

    @include mobile-landscape {
      flex-direction: column;

      li button {
        width: 100%;
      }
    }

    li {
      margin: 0 0;
    }
  }

  button {
    border: none;
    border-radius: .5em;
    background: $orange;
    color: white;
    padding: 1em 1.2em;
    font-size: 1em;
    box-shadow: 0 0 .28em .048em rgba(0, 0, 0, 0.3);
    cursor: pointer;

    &:hover {
      background: lighten($orange, 10);
    }
  }
}