@import '../../../styles/global.scss';

.scam-savvy-header {
  background-color: #fd0;
  padding: 1em 4em;
  display: flex;
  align-items: center;

  &.shadow {
    box-shadow: 0 0 20px 0px rgba(0,0,0,.1);
  }

  .scam-savvy-left {
    display: flex;
    align-items: center;
    gap: 1em;
    flex: 1;

    svg {
      height: auto;
      width: 3.9em;

      @include mobile-landscape {
        width: 1.9em;
      }
    }

    h1 {
      font-family: $gotham;
      font-size: 3em;
      margin: 0;
      text-transform: uppercase;

      @include mobile-landscape {
        font-size: 2em;
      }
    }
  }

  .scamm-savvy-right {
    text-align: right;

    svg {
      width: 5.7em;

      @include mobile-landscape {
        width: 3.7em;
      }
    }
  }


}

