@import '../../../styles/global.scss';

.container .container-inner > .one-pager-link-wrapper {
  width: 25.9%;
  height: 10%;
  inset: 1% 0 auto auto;

  @include mobile-landscape {
    inset: 0 auto auto 50%;
    transform: translateX(-50%);
    width: auto;
  }
}

.one-pager-link-wrapper {
  position: absolute;
  top: 1%;
  right: 0;
  z-index: 2;



  button {
    border: none;
    background-color: #d9b924;
    color: white;
    padding: 1em;
    font-size: 1em;
    border-radius: .48em 0 0 .48em;
    box-shadow: 0 0 .48em .048em rgba(0,0,0,.2);
    display: flex;
    gap: 1em;
    align-items: center;
    cursor: pointer;

    @include mobile-landscape {
      padding: .5em 1em;
      border-radius:  0 0 .48em .48em;
      border-left: 2px solid white;
      border-right: 2px solid white;
      border-bottom: 2px solid white;
    }

    img {
      height: 1.5em;
    }

    &:after {
      content: '';
      display: block;
      height: 1.1em;
      width: 1.1em;
      background-image: url('../../../assets/img/icon-chevron-right.svg');
      background-size: contain;
      background-repeat: no-repeat;
      transform: translateX(0);
      transition: .3s transform;
    }

    &:hover {
      &:after {
        transform: translateX(50%);
      }
    }
  }
}