@import '../../../../styles/global.scss';

.phone-wrapper {
  padding: 2em;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../../../../assets/img/bg-phone.jpg');
  position: relative;

  @include mobile-landscape {
    overflow: hidden;
  }
}

