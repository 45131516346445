@import '../../styles/global.scss';

@keyframes ringing-circles {
  0% {
    transform: translateX(-50%) scale(1);
    
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) scale(1.4);
    opacity: 0;
  }
}

@keyframes ringing-phone {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }

  100% {
    transform: translate(-50%, -50%) scale(1.2);
  }
}

.call-control {
  .subtitle-wrapper {
    position: absolute;
    top: 35%;
    right: 52%;
    width: 40%;

    @include mobile-landscape {
      width: 60%;
      right: 1%;
    }
  }
}

.call-inner {
  font-family: $roboto;
  position: absolute;
  width: 23%;
  height: 76%;
  left: 40%;
  top: 12%;
  transform: rotate(27.25deg);
  border-radius: 1.2em;

  @include mobile-landscape {
    width: 23%;
    height: 103%;
    left: 50%;
    top: 50%;
    transform: rotate(27.25deg) translate(-14.5vw, -14vh);
  }


  .call-name {
    width: 80%;
    font-size: 2em;
    color: white;
    margin: auto;
    text-align: center;
    margin-top: 2em;

    @include mobile-landscape {
      font-size: 1.4em;
    }
  }

  .call-avatar {
    width: 6.3em;
    height: 6.3em;
    object-fit: contain;
    display: block;
    margin: 2.5em auto;

    @include mobile-landscape {
      width: 4.3em;
      height: 4.3em;
    }
  }

  .call-ringing-wrapper {
    position: relative;
    margin-top:  5em;

    @include mobile-landscape {
      margin-top: 2em;
    }
    
    .call-ringing-circle {
      height: 4.3em;
      width: 4.3em;
      margin: auto;
      background-color: #00cc00;
      border-radius: 50%;;
      animation: ringing-circles 2s linear infinite;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
  
      &.inner-circle {
        animation-delay: 1s;
      }

      @include mobile-landscape {
        width: 3.5em;
        height: 3.5em;
      }
    }

    .call-answer {
      background-color: transparent;
      background-image: url('../../assets/img/icon-call-answer.svg');
      background-repeat: no-repeat;
      border: 0;
      height: 4.5em;
      width: 4.5em;
      margin: auto;
      display: block;
      cursor: pointer;
      animation: ringing-phone 1s linear alternate-reverse;
      position: absolute;
      top: 2.6em;
      left: 50%;
      transform: translate(-50%, -50%);

      @include mobile-landscape {
        width: 3.5em;
        height: 3.5em;
        top: 2.1em;
      }
    }
  }

  .call-hangup {
    background-color: transparent;
    background-image: url('../../assets/img/icon-call-hangup.svg');
    background-repeat: no-repeat;
    border: 0;
    height: 4.5em;
    width: 4.5em;
    margin: auto;
    display: block;
    cursor: pointer;

    @include mobile-landscape {
      width: 3.5em;
      height: 3.5em;
    }
  }




}