.lunch-note-blank-wrapper {
  padding: 2em;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background-size: cover;
  background-repeat: no-repeat;
  // background-image: url('../../../assets/img/lunch-note-blank.jpg');
  background-image: url('../../../assets/img/lunch-note.jpg');
  position: relative;
}