@import '../../../styles/global.scss';

.finance-window-2 {
  background-image: url('../../../assets/img/finance-software-2.png');
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  height: 75.5%;
  width: 87%;
  top: 16%;
  left: 3%;
  transform: rotateX(2deg); 

  @include mobile_landscape {
    width: 74%;
    left: 25%;
    transform: none; 
  }
}