@import '../../styles/global.scss';

.advice-wrapper {
  padding: 2em;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../../assets/img/bg-one-pager.jpg');
  position: absolute;
  inset: 0;
  z-index: 30;


  .advice-overlay {
    position: absolute;
    background-image: url('../../assets/img/bg-one-pager-overlay.png');
    left: 0;
    width: 29%;
    height: 52%;
    top: 8%;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 10;

    @include mobile-landscape {
      width: 32%;
      height: 83%;
    }
  }

  .advice-inner-wrapper {
    position: absolute;
    left: 17.5%;
    top: 0%;
    height: 77.5%;
    padding-top: 15%;
    width: 76%;
    background-color: transparent;

    @include mobile-landscape {
      width: 79%;
      top: 0;
      height: 100%;
      padding: 0;
    }

    .ScrollbarsCustom.trackYVisible .scroll-prompt {
      display: block;
    }

    .scroll-prompt {
      display: none;
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
      background: $orange;

      .ca3-scroll-down-arrow {
        // background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2hldnJvbl90aGluX2Rvd24iIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIwIDIwIiBmaWxsPSJ3aGl0ZSIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTE3LjQxOCw2LjEwOWMwLjI3Mi0wLjI2OCwwLjcwOS0wLjI2OCwwLjk3OSwwYzAuMjcsMC4yNjgsMC4yNzEsMC43MDEsMCwwLjk2OWwtNy45MDgsNy44M2MtMC4yNywwLjI2OC0wLjcwNywwLjI2OC0wLjk3OSwwbC03LjkwOC03LjgzYy0wLjI3LTAuMjY4LTAuMjctMC43MDEsMC0wLjk2OWMwLjI3MS0wLjI2OCwwLjcwOS0wLjI2OCwwLjk3OSwwTDEwLDEzLjI1TDE3LjQxOCw2LjEwOXoiLz48L3N2Zz4=);
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI3LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNoZXZyb25fdGhpbl9kb3duIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiCgkgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDIwIDIwOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxwYXRoIGQ9Ik0xNy40LDYuMWMwLjMtMC4zLDAuNy0wLjMsMSwwczAuMywwLjcsMCwxbC03LjksNy44Yy0wLjMsMC4zLTAuNywwLjMtMSwwTDEuNiw3LjFjLTAuMy0wLjMtMC4zLTAuNywwLTEKCWMwLjMtMC4zLDAuNy0wLjMsMSwwbDcuNCw3LjFMMTcuNCw2LjF6Ii8+Cjwvc3ZnPgo=);
        background-size: contain;
        background-repeat: no-repeat;
        cursor:pointer;
        height: 60px;
        width: 80px;
        margin: 0px 0 0 -40px;
        line-height: 60px;
        position: absolute;
        left: 50%;
        bottom: 0px;
        text-align: center;
        font-size: 70px;
        z-index: 100;
        text-decoration: none;
        text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
      
        -webkit-animation: ca3_fade_move_down 2s ease-in-out infinite;
        -moz-animation:    ca3_fade_move_down 2s ease-in-out infinite;
        animation:         ca3_fade_move_down 2s ease-in-out infinite;
      }

        /*animated scroll arrow animation*/
        @-webkit-keyframes ca3_fade_move_down {
          0%   { -webkit-transform:translate(0,-20px); opacity: 0;  }
          50%  { opacity: 1;  }
          100% { -webkit-transform:translate(0,20px); opacity: 0; }
        }
        @-moz-keyframes ca3_fade_move_down {
          0%   { -moz-transform:translate(0,-20px); opacity: 0;  }
          50%  { opacity: 1;  }
          100% { -moz-transform:translate(0,20px); opacity: 0; }
        }
        @keyframes ca3_fade_move_down {
          0%   { transform:translate(0,-20px); opacity: 0;  }
          50%  { opacity: 1;  }
          100% { transform:translate(0,20px); opacity: 0; }
        }
    }

    .advice-content {
      font-size: 1.3em;
      padding: 2em 4em;
      background: rgb(218,224,230);
      background: linear-gradient(140deg, rgba(218,224,230,1) 22%, rgba(255,255,255,1) 100%);

      @include mobile-landscape {
        padding: 2em;
      }

      h2 {
        font-family: $gotham;
        text-transform: uppercase;
        font-size: 2em;
        font-weight: bolder;
      }

      h3 {
        font-family: $gotham;
        text-transform: uppercase;
        text-decoration: underline;
        text-underline-offset: .3em;
        text-decoration-thickness: .25em;
        text-decoration-color: #fd0;
        font-size: 1.2em;
      }

      p {
        margin-bottom: 1em;
      }
    }
  }
}