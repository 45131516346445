@import '../../../styles/global.scss';

.voucher-purchase-wrapper {
  padding: 2em;
  height: 100%;
  width: 77%;
  margin-left: 23%;
  box-sizing: border-box;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('../../../assets/img/bg-send-email.jpg');
  position: relative;

  @include mobile_landscape {
    background-size: cover;
  }

  .email-body {
    position: absolute;
    top: 32%;
    left: 5%;
    width: 88%;
  }
}