@import '../../styles/global.scss';

.desk-wrapper {
  padding: 2em;
  background-size: contain;
  background-repeat: no-repeat;
  box-sizing: border-box;
  position: relative;

  > img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    object-fit: contain;

    @include mobile-landscape {
      object-fit: cover;
      object-position: center;
    }
  }
}