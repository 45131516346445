@import '../../../styles/global.scss';

.browser-wrapper.search {
  background-image: url('../../../assets/img/bg-browser.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  height: 70%;
  width: 90%;
  position: absolute;
  top: 20%;
  transform: rotateX(2deg);

  @include mobile-landscape {
    height: 76%;
    width: 69%;
    left: 26%;
    top: 13%;
    transform: none; 
  }

  .browser-inner {
    background-color: white;
    margin-top: 7%;
    height: 84%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10%;

    .search-logo {
      width: 30%;
      height: auto;
      display: block;
    }

    .search-input-wrapper {
      border: .1em solid #4686f4;
      width: 30%;
      border-radius: .7em;
      padding: .3em 1em;
      color: slategray;
      font-size: 1.3em;
    }

    .results-image {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: top left;

      @include mobile-landscape {
        
      }
    }
  }
}