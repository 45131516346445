@import '../../../../styles/global.scss';

.monitor-wrapper {
  padding: 2em;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../../../../assets/img/bg-imac.jpg');
  position: relative;
  perspective: 1000px;

  @include mobile-landscape {
    background-position: center -60px;
  }
}