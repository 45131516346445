@import '../../styles/global.scss';

.container {
  // max-width: 1066px;
  font-family: $lato;
  font-size: max(1em,1.4vw);
  user-select: none;
  // margin: 2em auto;
  width: calc(100% - 6rem);  
  border: 3rem white solid;
  max-width: calc(150vh - 9rem);
  margin: 0em auto;
  


  
  @include mobile-portrait {
    border: none;
    margin: 0;
    width: 100%;
    overflow: hidden;
  }

  @include mobile-landscape {
    border: none;
    margin: 0;
    max-width: 100%;
    width: 100%;
  }

  .portrait-warning {
    display: none;
    background-color: black;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h2 {
      color: white;
      font-family: $gotham;
      font-size: 8vw;
      text-transform: uppercase;
      text-align: center;
      padding: 1em 2em;
      margin: 0;
    }

    img {
      width: 60%;
      height: auto;
      display: block;;

    }

    @include mobile-portrait {
      display: flex;
    }
  }

  .container-inner {
    position: relative;
    height: 0;
    width: 100%;
    padding-top: 66.72%;

    @include mobile-portrait {
      display: none;
    }

    @include mobile-landscape {
      height: 100vh;
      padding-top: 0;
    }

    & > *:not(button) {
      position: absolute;
      inset: 0;
      height: 100%;
      width: 100%;
    }
  }
}

.reset-button {
  text-align: center;
}

@media screen and (min-width: calc(150vh - 9rem) ) {

  .container {
    font-size: max(1em, 2vh);
  }
}