@import '../../../styles/global.scss';

.browser-wrapper.stationary-1 {
  background-image: url('../../../assets/img/bg-browser.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  height: 70%;
  width: 90%;
  left: 5%;
  top: 20%;
  position: absolute;
  transform: rotateX(2deg);

  @include mobile-landscape {
    height: 76%;
    width: 69%;
    left: 26%;
    top: 13%;
    transform: none; 
  }

  .browser-url {
    position: absolute;
    top: 8%;
    left: 15%;
    font-size: .8em;

    @include mobile-landscape {
      top: 5%;
    }
  }

  .browser-inner {
    position: absolute;
    top: 14%;
    width: 96.6%;
    height: calc(86% - 2em);
    padding: 1em;
    background: #fff4a1;
    justify-content: flex-start;
    align-items: flex-start;
    color: black;
    display: flex;
    flex-direction: column;

    @include mobile-landscape {
      top: 12%;
      width: 92.6%;
      height: calc(83% - 2em);
      font-size: .8em;
    }

    p {
      margin: 0;
    }

    table.cart {
      color: black;
      border-collapse: collapse;
      border: .1em solid black;
      width: 50%;

      td, th {
        border: .1em solid black;
      }
    }
  }
}