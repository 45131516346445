@import '../../../styles/global.scss';

.back-button {
  border: none;
  background-color: $orange;
  padding: .483em .965em;
  border-radius: .483em;
  display: flex;
  align-items: center;
  gap: .3em;
  color: white;
  font-size: 1.3em;
  cursor: pointer;
  box-shadow: 0 0 .483em .0483em rgba(0,0,0,0.4);
  transition: .5s background-color;
  position: relative;
  z-index: 10;
  

  @include mobile-landscape {
    padding: .5em .5em;
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    // margin-top: -25px;
    // margin-left: -25px;
    border-radius:  0 0 .483em 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
  }

  &:hover {
    background-color: lighten($orange, 10);
    img {
      transform: translateX(-50%);
    }
  }

  img {
    transform: translateX(0);
    transition: transform .5s;
    height: .965em;
    width: .965em;
    object-fit: contain;
  }
}