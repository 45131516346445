@import '../../../styles/global.scss';

.subtitle-wrapper {
  align-items: center;
  display: grid;
  grid-template-columns: repeat(2, minmax(auto, 1fr));
  background: white;
  border-radius: 1em;
  padding: 2em;
  font-family: $lato;

  h3.subtitle-speaker {
    text-align: center;
    grid-column: 1/3;
    margin-bottom: .5em;
  }

  p.subtitle-text {
    font-size: 1.2em;
    line-height: 1.4em;
    margin: 0 auto;
    background-color: white;
    padding: 1em;
    text-align: left;
    color: black;
    font-family: arial;
    position: relative;
    grid-column: 1/3;
    border-radius: 1.2em;
    
    &:before {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      border-left: .48em solid white;
      border-right: .48em solid transparent;
      border-top: .48em solid transparent;
      border-bottom: .48em solid transparent;
      right: -2.6em;
      top: 1em;

      @include mobile-landscape {
        display: none;
      }
    }
    
    
  }

  .button-wrapper {
    text-align: center;
    padding: .5em;
    font-family: $lato;

    button {
      background-color: $orange;
      font-weight: bold;
      color: white;
      border: none;
      margin: 0;
      cursor: pointer;
      padding: .8em 1em;
      border-radius: 0.48em;
      box-shadow: 0 0 0.48em 0.048em rgba(0, 0, 0, 0.2);
      width: 100%;
      font-size: 1em;
    
  
      &[disabled] {
        background-color: white;
        color: lightgrey;
      }
    }
  }
  


}